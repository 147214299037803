














































import { Component, Vue } from 'vue-property-decorator';
import {
  dispatchGetTiposLocalidade,
  dispatchUpdateTipoLocalidade,
} from '@/store/tipo_localidade/actions';
import { consultarTipoLocalidade } from '@/store/tipo_localidade/getters';
import { ITipoLocalidadeUpdate } from '@/interfaces/tipo_localidade';

@Component
export default class AlterarTipoLocalidade extends Vue {
  public valid = true;
  public descricao: string = '';
  public sigla: string = '';

  public async mounted() {
    await dispatchGetTiposLocalidade(this.$store);
    this.reset();
  }

  public reset() {
    this.$validator.reset();
    if (this.tipoLocalidade) {
      this.descricao = this.tipoLocalidade.descricao;
      this.sigla = this.tipoLocalidade.sigla;
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const tipoLocalidadeAtualizado: ITipoLocalidadeUpdate = {
        descricao: this.descricao,
        sigla: this.sigla,
      };

      const atualizou = await dispatchUpdateTipoLocalidade(this.$store, {
        id: this.tipoLocalidade!.id,
        tipoLocalidade: tipoLocalidadeAtualizado,
      });
      if (atualizou) {
        this.$router.push('/main/tiposLocalidade/listar');
      }
    }
  }

  get tipoLocalidade() {
    return consultarTipoLocalidade(this.$store)(+this.$router.currentRoute.params.id);
  }
}
